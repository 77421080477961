<template>
    <div>
      
      <div id="circleProgress"></div>
       
    </div>
</template>

<script>
// @ is an alias to /src

var ProgressBar = require('progressbar.js')
import moment from "moment";
export default {
  name: 'CircleProgress',
  components: {
      
  },
  props:{
    divId:{
      required:true,
      type:String
    }
  },
  mounted() {
       
      this.sartProgress()

  },
  unmounted(){
      this.bar.destroy()
      console.log("xxxxxx")
    },
  data() {
    return {
      bar:null
    }
  },
    
  methods: {
  
    sartProgress(){
      
       let otpPeriod =90000;
        var difference = moment().diff(moment(localStorage.getItem("lastOtpTime")))
        let duration = otpPeriod - difference;

          let progress =1;
          if (duration<0) {
            duration =0;
            progress =0;
          }else{
            progress =  duration/otpPeriod;
          }
          if (!this.bar) {
                this.bar = new ProgressBar.Circle('#circleProgress', {
          color: '#FFFFFF',
          strokeWidth: 6,
          trailWidth: 1,
          easing: 'linear',
          text: {
            autoStyleContainer: false
          },
          from: { color: '#FFFFFF', width: 6 },
          to: { color: '#FFFFFF', width: 6 },
          // Set default step function for all animate calls
          step: function(state, circle) {
            circle.path.setAttribute('stroke', state.color);
            circle.path.setAttribute('stroke-width', state.width);

            var value = Math.round(circle.value() * 100);
            if (value === 0) {
              circle.setText('0');
            } else {
              circle.setText(value);
            }

          }
        });
        // bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
        this.bar.text.style.fontSize = '1.4rem';
      this.bar.text.style.opacity =.8
        // bar.set(1)
        }
     

      this.bar.set(progress)
      this.bar.animate(0,{
        duration: duration,
      },()=> {
        this.$emit("progress-finished")
      });  

        
    },
    
   
  },


}
</script>
