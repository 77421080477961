<template>
  <div>
     <div  class="wrapper-div">
       <TopBar @user-profile="setUserProfile" />
       <div  class="d-flex  ">
          <SideNav ActiveTab ="home"/>
          <div  class="container-fluid px-middle top-div">
            
            <div >
                <div class="row">
                  <div class="col-md-4 col-lg-4 col-sm-12 pr-0">
                    <div class="card data-card shadow-sm  home-card">
                        <div class="card-body h-full w-full d-flex flex-column ">
                          <div class="d-flex flex-fill">
                            <img  height="26" src="../assets/Wallaet_icon.svg" alt="logo" > 
                            <!-- <span style="font-size:1.5rem" class="fa fa-wallet muted-text"></span> -->
                            <p style="" class="mt-2 ml-3 text-sm">Total Wallets</p>
                          </div>
                          <div class="">
                            <p class=" mb-0"> <b>ugx  <span class="text-xl">{{(currentBalance)?formatNumber(currentBalance):'0.0'}}</span> </b> </p>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 pr-0">
                    <div class="card data-card shadow-sm  home-card">
                        <div class="card-body h-full w-full d-flex flex-column ">
                          <div class="d-flex flex-fill">
                            <!-- <img  height="26" src="../assets/Wallaet_icon.svg" alt="logo" >  -->
                            <span style="font-size:1.5rem" class="fa fa-home text-gray-600"></span>
                            <p style="" class="mt-2 ml-3 text-sm">Ntuma Accounts</p>
                          </div>
                          <div class="">
                            <p class=" mb-0"> <b>  <span class="text-xl">{{(ntumaAccountsCount<10)?"0":""}}{{ ntumaAccountsCount }}</span> </b> </p>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 pr-0">
                    <div class="card data-card shadow-sm  home-card">
                        <div class="card-body h-full w-full d-flex flex-column ">
                          <div class="d-flex flex-fill">
                            <!-- <img  height="26" src="../assets/Wallaet_icon.svg" alt="logo" >  -->
                            <span style="font-size:1.5rem" class="fa fa-users text-gray-600"></span>
                            <p style="" class="mt-2 ml-3 text-sm">Users</p>
                          </div>
                          <div class="">
                            <p class=" mb-0"> <b>  <span class="text-xl">{{(ntumaAccountsCount<10)?"0":""}}{{userCount}}</span> </b> </p>
                          </div>
                        </div>
                    </div>
                  </div>
                  
                 
                </div>

                <div class="row mb-6 ">
                  
                  <div class="col-md-7 col-sm-7 col-xs-7 col-lg-7 col-xl-7 pr-0">
                    <div  class="card data-card shadow-sm h-full  ">
                        <div class="card-body pt-4">
                            <div class="d-flex mb-1">
                                <div class="w-1-of-2">
                                    <p class=""> <b>Transactions</b> </p>
                                </div>
                                <div class="d-flex w-1-of-2 justify-content-end">
                                  <!-- <img height="17" class="mr-3"  src="../assets/filter_icon.svg" alt="logo" > -->
                                    <span class="text-12">
                                    Last 12 months</span> 
                                </div>
                            </div>
                            
                            <div style="height:350px" id="barchart">
                              <DataLoader ref="dataLoader" />
                            </div>
                           
                        </div>
                    </div>
                  </div>
                  <div class="col-md-5 col-sm-5 col-xs-5 col-lg-5 col-xl-5 pl-3 ">
                        
                        <div style="height:460px" class="card h-full data-card shadow-sm  mb-0">
                            <div style="overflow-y:auto" class="card-body pb-3 pt-4 ">
                               <div class=" mb-4">
                                  <div class="w-1-of-2">
                                      <p class=""> <b>Transaction History</b> </p>
                                  </div>
                              </div>
                              <DataLoader ref="dataLoaderHistory" />
                              <p v-if="noCollectionsYet" class="text-center w-full pt-7 text-13">No Records</p>

                              <div :key="key" v-for="(item,key) in collections" class="d-flex simple-timeline">
                                <div   :class="(item.status=='pending')?'left-side-pending':(item.status=='failed')?'left-side-pending':(item.status=='successful')?'left-side-success':''" class=" mr-2 pr-2 pb-4 left-side">
                                  <span class="pr-3">{{formatDate3(item.created_at)}}</span>
                                </div>
                                <div class="pl-3">
                                  <span> {{ formatNumber(item.amount) }}</span>
                                </div>
                              </div>

                            
                            </div>
                        </div>
                  </div>
                </div>

                


                 
            </div>
            
            <!-- <div style="padding-left:235px" class="fixed-bottom d-flex bg-danger justify-content-center"> -->
              
            <!-- </div> -->

           
           

          </div>

         
          
        </div>

      
       
     </div>
  </div>
 
 
 
 
 </template>
 
<script>
// @ is an alias to /src
import commons from "../commons"
import axios from "axios";
import TopBar from './Elements/TopBar.vue'
import SideNav from './SideNav'
import charts2 from "../mixin/charts2"
import DataLoader from './Elements/DataLoader.vue'

export default {
  name: 'HomePage',
  mixins:[commons,axios,charts2],
  components: {
    SideNav,TopBar,DataLoader
  },
  data() {
      return {
        userProfile:{},
        ntumaAccount:{},
        currentBalance:0,
        userCount:0,
        ntumaAccountsCount:0,
        enviromentMode:"test",
        collections:[],
        noCollectionsYet:false,
        progressValue:0.6,
      }
  },
  mounted() {
    this.getDashboardStats()
    this.getCollections()
  },

  methods: {
    openSettlements(){
      this.$router.push("/settlements");
    },
    openProfiles(){
      this.$router.push("/account-details");
    },
    getDashboardStats(){
      this.$refs.dataLoader.start()
      axios.get(this.baseUrl+"/api/admin/dashboard-stats",{
          headers: {
              Authorization: 'Bearer ' + this.getCookie("accessToken"),
          }
      }).then((res)=>{
       
         const series = [{name: 'Recieved', data: res.data.data.data}];
         const categories = res.data.data.keys
         this.currentBalance = res.data.data.totalBalance
         this.userCount = res.data.data.users_count
         this.ntumaAccountsCount = res.data.data.ntuma_accounts_count
         this.createLineChart("barchart",categories,series)
        this.$refs.dataLoader.start()
        console.log(series)


      }).catch(()=>{
          
      })
    },
   
    setUserProfile(userProfile){
      this.userProfile = userProfile
    },

    getCollections(){
      this.$refs.dataLoaderHistory.start()
      const collectionsUrl = this.baseUrl+"/api/admin/collections";  
      axios.get(collectionsUrl,{
            headers: {
                Authorization: 'Bearer ' + this.getCookie("accessToken"),
            }
        }).then((res)=>{
          this.$refs.dataLoaderHistory.stop()
            this.collections = res.data.data.items;
            this.noCollectionsYet= (this.collections.length ==0)
        }).catch((error)=>{
            console.log(error);
        })
    }
      
  },
}
</script>
