
<template>
     <div class="">
       <div class="input-group ">
          <input type="text" class="form-control search-input top-h" name="email_or_phone">
          <span style="color:#051A3580" class="input-group-text px-3 search-input   ml-0">  <span  class="fa fa-search"></span> </span>
        </div>
    </div>
</template>
<script>

  export default {
    name:'search-component',
    components: {},
    props:{
     
    },
    mounted(){
        // $(document).click(function(){
        //     $("#search_dropdown").hide();
        // });

        // $("#search_dropdown").click(function(e){
        //     e.stopPropagation();
        // });
    },

    data () {
      return {
        searchResults:[],
        search_query:'',
        isSearching:false,
      }
    },

    methods:{
      onSearch(){
           
        }
    },
  }
</script> 