import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Login from "../views/Login.vue";
import ConfirmIdentity from "../views/ConfirmIdentity.vue";
import Users from '../views/User/Users.vue'
import NtumaAccounts from '../views/NtumaAccounts/NtumaAccounts.vue'
import Collections from '../views/Collections/Collections.vue'
import Settlements from '../views/Settlements/Settlement.vue'


const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Login
  },
  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
 
  {
    path: '/confirm-identity',
    name: 'ConfirmIdentity',
    component: ConfirmIdentity
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },

  {
    path: '/ntuma-accounts',
    name: 'NtumaAccounts',
    component: NtumaAccounts
  },

  {
    path: '/collections',
    name: 'Collections',
    component: Collections
  },
  {
    path: '/settlements',
    name: 'Settlements',
    component: Settlements
  },
  // {
  //   path: '/choose-account',
  //   name: 'Collections',
  //   component: Collections
  // },


  
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
