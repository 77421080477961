
/* eslint-disable */
const charts = {

    data() {
        return {
            chartData:{}
        }
    },

    mounted() {
        // this.createLineChart();        
    },

    methods: {
        createLineChart(id,categories,series){
            var dataCapturedChart = $("#"+id);
            if(dataCapturedChart.length > 0) {
                Highcharts.chart('barchart', {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: ''
                    },
                   
                    xAxis: {
                        categories:categories,
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        valueSuffix: ''
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        },
                        series: {
                            borderRadius: 5
                        }
                    },
                    colors: [
                        '#9e9d9d',
                        '#7C66FF',
                    ],
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: series
                });
            }
            
        },
        createDoughnut(id){
            // Create the chart
            var dataCapturedChart = $("#"+id);
            if(dataCapturedChart.length > 0) {
                // var collectedData = JSON.parse(dataCapturedChart.attr("data-chart"));
            var collectedData =this.chartData[id];
            var chart = new Highcharts.Chart({
                  chart: {
                      renderTo: id,
                      type: 'pie',
                  },
                  credits: {
                      enabled: false,
                 },
                  title: {
                      text: collectedData.title,
                      align: 'center',
                      verticalAlign: 'middle',
                      y: -10
                  },
                  yAxis: {
                      title: {
                          text: ''
                      }
                  },
                  plotOptions: {
                      pie: {
                          shadow: false
                      }
                  },
                  legend:{
                      itemStyle:{
                          fontWeight:"500"
                      }
                  },
                  
                  series: [{
                      name: '',
                      data:  collectedData.data,
                    //   color:collectedData.color,
                      innerSize: '88%',
                      showInLegend:true,
                      dataLabels: {
                          enabled: false
                      }
                  }]
              });
            }    
        },

        drawLineChart(id){
            var dataCapturedChart = $("#"+id);
            if(dataCapturedChart.length > 0) {
                Highcharts.chart(id, {
                    title: {
                        text: ''
                    },
    
    
                    yAxis: {
                        title: {
                            text: 'Total'
                        }
                    },
    
                    xAxis: {
                        categories:["July, 2021","Aug, 2021","Sep, 2021","Oct, 2021","Nov, 2021","Dec, 2021","Jan, 2022","Feb, 2022"]
                        
                    },
    
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
    
                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false
                            },
                            // pointStart: 2014
                        }
                    },
    
                    series: [{
                            name: 'Users',
                            data: [45, 50, 46, 50, 70, 60, 70, 129]
                        }, {
                            name: 'Drivers',
                            data: [10, 6, 6, 11, 15, 20, 24, 30]
                        }
                    ],
    
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
    
                });
            }
           
        },

        drawLineChart2(id){
            var dataCapturedChart = $("#"+id);
            if(dataCapturedChart.length > 0) {
                Highcharts.chart(id, {
                    title: {
                        text: ''
                    },
    
    
                    yAxis: {
                        title: {
                            text: 'UGX'
                        }
                    },
    
                    xAxis: {
                        categories:["July, 2021","Aug, 2021","Sep, 2021","Oct, 2021","Nov, 2021","Dec, 2021","Jan, 2022","Feb, 2022"]
                        
                    },
    
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
    
                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false
                            },
                            // pointStart: 2014
                        }
                    },
                    series: [{
                        name: 'Trips',
                        data: [45000, 50000, 46000, 50000, 70000, 60000, 60000, 89000]
                    }, {
                        name: 'Car Hires',
                        data: [10000, 6000, 6000, 11000, 15000, 7000, 24000, 21000]
                    }, {
                        name: 'Packages',
                        data: [34000, 33000, 38000, 27000, 21000, 41000, 40000, 33000]
                    },
                    {
                        name: 'Boat Cruises',
                        data: [97000, 123000, 108000, 127000, 101000, 111000, 140000, 133000]
                    }
                ],
    
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
    
                });
            }
           
        }
      
    }
};

export default charts;
