
<template>
  <div>
     <slot :isVisible="isVisible"></slot> 
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'PasswordInput',
  components: {
    
  },
  data() {
    return {
      isVisible:false,
    }
  },
  props:[],
  mounted() {
  },
  methods: {
    changePasswordVisibility(){
      console.log("cccc")
      if (this.isVisible==true) {
        this.isVisible = false
      }else{
        this.isVisible = true;
      }
    }
  },


}
</script>
