<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  
}

#nav {

}
</style>
