<template>
  <div>
    <input :class="inputClass" class="form-control" v-model.lazy="price" v-money="money" /> 
    <input  hidden type="text" :name="inputName" :value="price">

  </div>
</template>

<script>
  import {VMoney} from 'v-money'

  export default {
    props:{
        inputName:{
        type: String,
        required:false,
      },
      inputClass:{
        type: String,
        required:false,
      },
    },
    watch:{
      price:function(){
          var amount=this.price.replace(/,/g,''); // 1125, but a string, so convert it to number
          amount=parseFloat(amount);
          // amount = parseFloat(amount);
          this.$emit('amount-entered', amount);
      }
    },
    data () {
      return {
        price: 0.0,
        money: {
          decimal: '.',
          thousands: ',',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: false
        }
      }
    },

    methods: {
      setValue(value){
        this.price = value
      }
    },

    directives: {money: VMoney}
  }
</script>