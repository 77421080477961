import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// import jQuery from "jquery"
// window.$ = window.jQuery = jQuery;
import "popper.js";
import "bootstrap";
import "./assets/scss/app.scss"

const app = createApp(App);
app.use(router)

router.isReady().then(()=>{
    app.mount('#app')
})

// createApp(App)
// .use(router)
// .mount('#app')


