<template>
    <div>
      <button :disabled="(isLoding)?true:false" @click.prevent="buttonClicked()" :class="Class"> 
        <span v-if="isLoding">
            <div class="spinner-border spinner-border-sm"></div>
            Please wait
        </span>
        <span v-else>{{Text}}</span>
 </button>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'VueButton',
  components: {
    
  },
  data() {
    return {
      isLoding:false
    }
  },
  props:["Class","Text"],
  methods: {
      buttonClicked(){
          this.isLoding = true;
          this.$emit('buttonClicked');
      },

      finishProcessing(){
        this.isLoding = false;
      }
  },


}
</script>
