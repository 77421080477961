<template>
    <div class="text-center ">
      <div style="width:1.5rem; height:1.5rem; border-width: 0.15em;" v-if="isLoding" class="spinner-border mt-5"></div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'DataLoader',
  components: {
    
  },
  data() {
    return {
      isLoding:false
    }
  },
  props:[],
  methods: {
      start(){
          this.isLoding = true;
      },

      stop(){
        this.isLoding = false;
      }
  },


}
</script>
