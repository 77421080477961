<template>
  <div class="container">
    <div class="row justify-content-center pt-7 pt-auth-container pb-4">
        <div class="col-md-12 col-lg-9 col-xl-8 col-sm-12 col-xs-12">
          <div class="card sharp-card">
              <div class="card-body py-0 pr-0 px-mobile">
                  <div class="row">
                 
                      <div style="padding-top:4.5rem" class="col-md-6 pt-mobile pb-mobile">
                          <div class="d-flex justify-content-center">
                            <div class=" w-4-of-5 px-3">
                              <router-link class="" to="/login">
                                <img width="25"  src="../assets/back_button.svg" alt="logo" >
                             </router-link>
                              <p class="login-header mt-4 text-lg"> <b>Confirm Identity</b></p>

                              <div class="pr-5">
                              <img  class="w-full mt-2"  src="../assets/login_chair_icon.svg" alt="logo" >

                              </div>
                            </div>
                          </div>
                          
                      </div>
                      <div class="col-md-6 bg-login py-5">
                        <div class="text-center mt-3">
                          <img width="80" src="../assets/ntuma_logo.svg" alt="logo" >
                        </div>
                        <div class="container px-5">
                          <div class="d-flex mt-4 tagline">
                              <div class="w-1-of-3">
                                <hr>
                              </div>
                              <div class="w-1-of-3 text-center">
                                <small class="text-white-muted" style="font-size:10px">Tagline</small>
                              </div>
                              <div class="w-1-of-3">
                                <hr>
                              </div>
                          </div>
                        </div>

                        <div class="container pt-3">
                          <div class="d-flex justify-content-center mb-3">
                              <div class="w-1-of-5">
                                <CircleProgress divId="confirmIdentityCounter" @progress-finished="tokenExpired"/>
                              </div>
                          </div>
                          <!-- <p  v-if="showResendBtn" class="text-white-muted singup-label text-center my-4"  > 
                            <span v-if="!sendingOtp">Session timed out. <a @click.prevent="resendOtp" class="link1" href="">Resend Code?</a></span>
                            <span v-else>Sending please wait...</span>
                          </p> -->
                          <p  class="text-white-muted singup-label"><small>We emailed you a six-digit code to {{emailAddress}}. 
                            Enter the code below to confirm your identity.</small></p>
                          <div class="d-flex">
                            <div class="w-1-of-7 mr-3">
                                 <input type="text" maxlength="1" v-on:keyup="focusNext('auth-c-1', 'auth-c-2')" id="auth-c-1" class="form-control auth-input px-0 text-center" name="auth_pin">
                            </div>
                            <div class="w-1-of-7 mr-3">
                                 <input type="text" maxlength="1" v-on:keyup="focusNext('auth-c-2', 'auth-c-3')" id="auth-c-2" class="form-control auth-input px-0 text-center" name="auth_pin">
                            </div>
                            <div class="w-1-of-7 mr-4"> 
                                 <input type="text" maxlength="1" v-on:keyup="focusNext('auth-c-3', 'auth-c-4')" id="auth-c-3" class="form-control auth-input px-0 text-center" name="auth_pin">
                            </div>
                            <!-- <div class="w-1-of-7 col-md-1">
                              
                            </div> -->
                            <div class="w-1-of-7 mr-3 ml-4">
                                 <input type="text" maxlength="1" v-on:keyup="focusNext('auth-c-4', 'auth-c-5')" id="auth-c-4" class="form-control auth-input px-0 text-center" name="auth_pin">
                            </div>
                            <div class="w-1-of-7 mr-3">
                                 <input type="text" maxlength="1" v-on:keyup="focusNext('auth-c-5', 'auth-c-6')" id="auth-c-5" class="form-control auth-input px-0 text-center" name="auth_pin">
                            </div>
                            <div class="w-1-of-7">
                                 <input type="text" maxlength="1" id="auth-c-6" class="form-control auth-input text-center px-0" name="auth_pin">
                            </div>
                          </div>

                          <p style="font-size:14px; color:#" class=" mt-3"><small>Make sure to keep this window open while you check your inbox</small></p>

                          <!-- <button  class="btn px-2 mt-2 project-btn-yellow w-full">
                              Verify
                          </button> -->
                          <Button ref="verifyBtn" @button-clicked="verifyOtp()" Text="Verify"
                              Class="btn px-2 mt-2 project-btn-yellow w-full"/>
                          <p class="text-center singup-label mt-5 mb-3"> <small> <span class="text-white-muted singup-label">Don't have an account?</span> 
                            <router-link class="link1" to="/sign-up-individual">
                                    Sign Up
                            </router-link>
                          </small>
                           </p>
                        </div>

                      
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>

</template>

<script>
import commons from "../commons"
import axios from "axios";
import Button from "./Elements/Button.vue"
import iziToast from "../IziToast"
import CircleProgress from "./Elements/CircleProgress.vue"
import moment from "moment"

export default {
  name: 'ConfirmIdentity',
  mixins:[commons,axios,iziToast],
  components: {
    Button,CircleProgress
  },
  data() {
      return {
        emailAddress:"name@compnay.com",
        sendingOtp:false,
        showResendBtn:false,
        dataParams:{}
      }
  },
  mounted() {
     this.openPage ="auth_page";
     this.$nextTick(() => {
        this.dataParams =this.$route.params
        console.log(this.$route.params)
        if (this.$route.params.email) {
          this.emailAddress = this.$route.params.email;
        }
        if (this.$route.params.otp) {
            this.setPin(this.$route.params.otp)
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('otp')) {
            this.setPin(urlParams.get('otp'))
        }
        this.emailAddress = urlParams.get('email');
         
      });
  },

  methods: {
    resendOtp(){
          this.sendingOtp = true;
           axios({
                method: 'post',
                url: this.baseUrl+"/api/admin/resend-account-verification-code",
                headers: {}, 
                data: {
                    email_or_phone: this.emailAddress,
                }
            }).then((res)=>{
                 if (res.data.status =="success") {
                   this.setPin(res.data.data.otp)
                   this.showSuccessMessage("OTP Code resent")
                   this.showResendBtn = false
                    localStorage.setItem("lastOtpTime", moment());
                 }
                this.sendingOtp = false


            }) .catch( ()=> {
                this.showErrorMessage("Something went wrong")
                this.sendingOtp = false
            });

    },

     tokenExpired(){
        this.showResendBtn = true
        this.$router.push({
          name: "Login", 
        });
      },
      verifyOtp(){
        var otp ="";
        for (let index = 1; index <= 6; index++) {
          let otpInput=document.getElementById("auth-c-"+index).value
          otp = otp+""+otpInput;
        }
        if (otp.length ==6) {
             axios({
                method: 'post',
                url: this.baseUrl+"/api/admin/login-otp",
                headers: {}, 
                data: {
                    email_or_phone: this.emailAddress,
                    code: otp,
                }
            }).then((res)=>{
                this.$refs.verifyBtn.finishProcessing();
                if (res.data.status=="success") {
                    // localStorage.setItem("accessToken",res.data.data.accessToken);
                    // localStorage.setItem("refreshToken",res.data.data.refreshToken);
                    this.saveAuthenticationInCookies("accessToken",res.data.data.accessToken)
                    this.saveAuthenticationInCookies("refreshToken",res.data.data.refreshToken)

                    this.$router.push({
                      name: "Home"
                    });

                }else{
                  this.showErrorMessage("Otp Verification Failed")
                }
            }) .catch( ()=> {
                this.showErrorMessage("Otp Verification Failed")
                this.$refs.verifyBtn.finishProcessing();
            });
        }
      
      },
    
      setPin(value){
        let splitedString= value.split("");
        console.log(splitedString)
        var i=1;
        for (const num of splitedString) {
          document.getElementById("auth-c-"+i).value =num;
          i++; 
        }

      },
       focusNext(a, b) {
            let ina = document.getElementById(a);
            if(ina.value.length == 1) {
              document.getElementById(b).focus();
            }
        },

      saveAuthenticationInCookies(key,value){
        const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          if(process.env.VUE_APP_PARENT_DOMAIN ==".ntuma.app"){
            document.cookie = key+"="+value+"; expires="+tomorrow+"; domain="+process.env.VUE_APP_DOMAIN+"; Secure;";
          }else{
            document.cookie = key+"="+value+"; expires="+tomorrow+"; domain="+process.env.VUE_APP_DOMAIN+";";
          }
          // console.log(key+"="+value+"; expires="+tomorrow+" domain="+process.env.VUE_APP_DOMAIN+";")
      }
  },
}
</script>
