<template>
    <div class="wrapper-div ">
      <TopBar @enviroment-changed="enviromentChanged" @account-switched ="accountSwitched" />
    
        <div  class="d-flex  ">
          <SideNav ActiveTab ="users"/>
          <div  class="container-fluid px-middle top-div">
             
              <div  class="card data-card shadow-sm ">
                  <div class="card-body def-card-h pt-5 px-5">
                      <div class="">
                          <div class=" ">
                              <div class="d-flex px-table-top mb-2">
                                  <div class="w-5-of-6 d-flex">
                                      <p  > <b>Users</b> </p>
                                  </div>
                                 
                                   <div class="d-flex justify-content-end w-1-of-6  pb-3">
                                          <!-- <img height="20" class="mr-3"  src="../../assets/download.svg" alt="logo" > -->
                                  </div>
                              </div>
                              <table style="box-shadow: none;" class="table table-borderless  table-hover">
                                  <thead class="">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Email Verified</th>
                                            <th>Created</th>
                                        </tr>
                                      
                                  </thead>
                                  <tbody>
                                      <tr v-for="(user,key) in ntumaUsers" :key="key">
                                         
                                          <td>
                                              <p class="mt-link"> {{user.firstname}} {{user.lastname}}</p>
                                          </td>
                                          <td>
                                              <p class="mt-link"> {{user.email}}</p>
                                          </td>
                                          <td>
                                              <p class="mt-link"> {{(user.phone)?user.phone:"NA"}}</p>
                                          </td>
                                          <td>
                                            <p class="mt-link ">
                                                <span class="badge bagde-3-success text-13 text-gray-800" v-if="user.account_verified">Yes</span>
                                                <span class="badge bagde-3-danger text-13 text-gray-800" v-else>No</span>
                                            </p>
                                          </td>
                                          <td>
                                              <p class="mt-link"> {{formatDate2(user.created_at)}}</p>
                                          </td>
                                          
                                      </tr>
                                  
                                  </tbody>
                              </table>
                              
                              <p v-if="noRecordsYet" class="text-center mt-6 text-13">No Records</p>
  
                              <div  class="justify-content-end mt-5 d-flex">
                                  <div>
                                      <button :disabled="currentPage==1" @click="getPreviousPage" class="btn btn-outline-secondary btn-sm">Previous</button>
                                      <button :disabled="currentPage==totalPages" @click="getNextPage" class="btn btn-outline-secondary btn-sm ml-3">Next</button>
                                  </div>
                              </div>
                             
                              <DataLoader ref="dataLoader"/>
                          
                          </div>
                      </div>
                  </div>
  
              </div>
              
  
              
          
              
          </div>
          
        </div>
    </div>
  
  </template>
  
  
  <script>
  // @ is an alias to /src
  import SideNav from '../SideNav.vue'
  import TopBar from '../Elements/TopBar.vue'
  import commons from "../../commons"
  import axios from "axios";
  // import Button from "../Elements/Button.vue"
  import iziToast from "../../mixin/IziToast"
  import DataLoader from "../Elements/DataLoader.vue"
  
  export default {
    name: 'WebHooks',
    mixins:[commons,axios,iziToast],
    components: {
      SideNav,TopBar,DataLoader
    },
    data() {
        return {
          noLinksYet:true,
          createNewLink:false,
          nextCursor:null,
          prevCursor:null,
          ntumaUsers:[],
          noRecordsYet:false,
          totalPages:0,
          lastCursor:null,
          currentPage:1,
      
        }
    },
    mounted() {
  
      this.getUsers()
    },
  
    methods: {
    
      accountSwitched(){
          this.getUsers();
      },
      enviromentChanged(){
          this.getUsers()
      },
     
      getNextPage(){
          this.getUsers()
           this.currentPage+=1;
      },
      getPreviousPage(){
          this.nextCursor = this.prevCursor
          this.getUsers()
          this.currentPage-=1;
      },
      getUsers(){
          this.noRecordsYet = false
          this.ntumaUsers =[]
          this.$nextTick(() => {
              this.$refs.dataLoader.start()
          });
  
          var ntumaUsersUrl =""
          if (this.nextCursor) {
              ntumaUsersUrl = this.baseUrl+"/api/admin/users?cursor="+this.nextCursor;
          }else{
              ntumaUsersUrl = this.baseUrl+"/api/admin/users";
          }
           axios.get(ntumaUsersUrl,{
                  headers: {
                      Authorization: 'Bearer ' + this.getCookie("accessToken"),
                  }
              }).then((res)=>{
                  this.ntumaUsers =[];
                  this.ntumaUsers.length =0;
                  this.ntumaUsers = res.data.data.items;
                  this.nextCursor =res.data.data.nextCursor
                  this.$refs.dataLoader.stop()
                  if (res.data.data.items.length ==0) {
                      this.noRecordsYet = true
                  }
                  this.totalPages = res.data.data.totalPages
                  this.lastCursor = res.data.data.lastCursor
              }).catch((error)=>{
                  console.log(error);
              })
      }
        
    },
  }
  </script>
  