<template>
  <div class="custom-nav">
      <nav id="sidebar">
        <ul class="list-unstyled components ">
            <li :class=" ActiveTab =='home'?'active':''" class="">
                <router-link to="/home">
                   <span>Overview</span>
                </router-link>
            </li>

            <li :class=" ActiveTab =='users'?'active':''" class="">
                <router-link to="/users">
                   <span>Users</span>
                </router-link>
            </li>
            <li :class=" ActiveTab =='ntumaAccounts'?'active':''" class="">
                <router-link to="/ntuma-accounts">
                   <span>Ntuma Accounts</span>
                </router-link>
            </li>
             <li :class=" ActiveTab =='collections'?'active':''" class="">
                <router-link to="/collections">
                   <span>Collections</span>
                </router-link>
            </li>

            <li :class=" ActiveTab =='settlements'?'active':''" class="">
                <router-link to="/settlements">
                   <span>Settlements</span>
                </router-link>
            </li>

            <li :class=" ActiveTab =='approvals'?'active':''" class="">
                <a  href="#mybusiness"  class="d-flex">
                    <span class="w-full ">Reports</span>
                </a>
            </li>

            
            
        </ul>

          

      </nav>

  </div>
</template>

<script>
export default {
   name: 'SideNav',
  components: {
    
  },
  props:["ActiveTab"],
  mounted() {
    console.log(this.ActiveTab)
  },
  methods: {
    logOut(){
        this.deleteCookie("accessToken")
      this.deleteCookie("refreshToken")
      this.$router.push("/");
    }
  },
}
</script>